import React, { useContext } from 'react';
import { SnackbarProvider } from 'notistack';
import { Box } from '@mui/material';
import PostUploadForm from './PostUploadForm';
import { AuthContext } from '../../../../../context/AuthContext';

const PostUploadPage = () => {
  const { authData } = useContext(AuthContext);

  // Debug: Log the user data
  //console.log('User in PostUploadPage:', authData);

  // If no user or not staff, show a message or redirect
  if (!authData || !authData.is_staff) {
    return (
      <Box sx={{ mt: 6 }}>
        <h3>You do not have permission to upload posts.</h3>
      </Box>
    );
  }

  // Otherwise, render the upload form
  return (
    <Box sx={{ mt: 6 }}>
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <PostUploadForm />
      </SnackbarProvider>
    </Box>
  );
};

export default PostUploadPage;