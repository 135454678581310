import React from 'react';
import { Box, Typography, CardActionArea } from '@mui/material';

const WelcomeMessage = () => {
  return (
    <CardActionArea sx={{ height: '100%' }}>
    <Box
      sx={{
        backgroundImage: 'url(/Carbon.png)',  // Path to the image in the public directory
        backgroundSize: 'cover',              // Ensures the image covers the entire box
        backgroundPosition: 'center',         // Centers the image
        backgroundRepeat: 'no-repeat',        // Prevents the image from repeating
        color: 'white',
        padding: 2,
        flexDirection: 'column',
        height: '90%', 
        borderRadius: 2,
        textAlign: 'center',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        margin: '0.5em 0',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 6px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
      Welcome to the Axillon Aerospace information portal!  
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Over time we envision this portal to be a communication hub for all the latest Axillon news, 
        important Axillon links, and messages from our leadership team.  
        <br /><br />
        Sincerely,<br />
        Axillon Aerospace Leadership
      </Typography>
    </Box>
    </CardActionArea>
  );
};

export default WelcomeMessage;