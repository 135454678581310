import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import 'chart.js/auto';
import { fetchTicketsTSA } from '../chartsAPI/jiraApi'; 
// Register Chart.js Plugins
Chart.register(ChartDataLabels);

const TicketsByStatusWaterfallChart = () => {
  const [projectKey, setProjectKey] = useState('ITP'); // Replace with a valid project key
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTicketsTSA(projectKey); // Fetch data from API
        setTickets(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectKey]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Extract labels (statuses) and values (counts)
  const labels = tickets.map(ticket => ticket.status);
  const values = tickets.map(ticket => ticket.count);

  // Compute Waterfall Data (Cumulative Sums)
  let cumulative = 0;
  const waterfallData = values.map((value, index) => {
    const previous = cumulative;
    cumulative += value;
    return { start: previous, end: cumulative };
  });

  // Prepare the dataset with "base" bars and "value" bars
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Base', // Base bars to set the start position
        data: waterfallData.map(d => d.start),
        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent
        borderWidth: 0,
        stack: 'stack1',
      },
      {
        label: 'Increase',
        data: values,
        backgroundColor: '#3498db', // Blue for increases
        borderColor: '#2980b9',
        borderWidth: 1,
        stack: 'stack1',
      },
    ],
  };

  // Chart options for a Waterfall Chart
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false }, // Hide legend
      datalabels: {
        anchor: 'end',
        align: 'top',
        formatter: (value) => value, // Show values above bars
        font: { weight: 'bold', size: 12 },
        color: '#000',
      },
    },
    scales: {
      x: {
        title: { display: true, text: 'Status' },
      },
      y: {
        beginAtZero: true,
        title: { display: true, text: 'Cumulative Count' },
      },
    },
  };

  return (
    <div>
      <h1>Tickets by Status (Waterfall Chart) for {projectKey}</h1>
      <Bar data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default TicketsByStatusWaterfallChart;