import React from 'react';
import FileUploader from './FileLoader';

// We'll reuse the valid types from your code or define them here
const validExcelTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const SafranOrderUploader = () => {
  return (
    <FileUploader
      title="Upload Safran Customer Orders"
      uploadUrl="https://api.axillonaerospace.info/api/upload_safran_order/"
      acceptedFileTypes={validExcelTypes}
      acceptAttribute=".xls,.xlsx"
      // Optionally handle success or error with a custom callback
      onSuccess={(data) => {
        console.log("Safran orders uploaded!", data);
        // Do something else if needed
      }}
      style={{
        // Example of adding custom styles
        backgroundColor: '#f9f9f9',
      }}
    />
  );
};

export default SafranOrderUploader;