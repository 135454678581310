import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import PrivateRoute from '../components/intranet/PrivateRoute';
import HomePage from '../components/intranet/HomePage';
import Ehs from '../components/intranet/ehs';
import ContactPage from '../components/intranet/ContactPage';
import Login from '../components/intranet/LoginPage';
import PasswordReset from '../components/intranet/PasswordReset';
import Register from '../components/intranet/RegisterPage';
import ResetPasswordForm from '../components/intranet/ResetPasswordForm';
import OrderManager from '../components/apps/OrderManager/OrderManager';
import WebApps from '../components/apps/WebApps';
import BlogListKevin from '../components/intranet/Blogs/Posts/Kevin/BlogListKevin';
import BlogListNews from '../components/intranet/Blogs/Posts/News/BlogListNews';
import BlogDetailKevin from '../components/intranet/Blogs/Posts/Kevin/BlogDetailKevin';
import BlogDetailNews from '../components/intranet/Blogs/Posts/News/BlogDetailNews';
import PostUploadPage from '../components/intranet/Blogs/Posts/Uploader/PostUploadPage';
import MarketingMaterials from '../components/intranet/Marketing/MarketingMaterials';
import ReactGA from 'react-ga4';
import TicketsPage from '../components/dashboards/charts/IT/ITTicketsPage';
import TicketsStatus from '../components/dashboards/charts/IT/TSATickets';
import TicketsByStatusWaterfallChart from '../components/dashboards/charts/IT/waterfall';
import Dashboard from '../components/dashboards/Dashboard';
import Header from '../components/intranet/Header'; // ✅ Ensures header is always displayed
import BlogListEHS from '../components/intranet/Blogs/Posts/EHS/BlogListEHS';
import { useAuth } from '../context/AuthContext';

// Initialize Google Analytics
ReactGA.initialize('G-41QLH62W9Y');

// Track page views
const usePageTracking = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);
};

const AppRoutes = () => {
  usePageTracking();
  const { loading } = useAuth(); // ✅ Wait for authentication state before rendering routes

  if (loading) {
    return <p style={{ marginTop: '20px', textAlign: 'center' }}>Loading...</p>;
  }

  return (
    <>
      <Header /> {/* ✅ Ensure header is always displayed */}
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/reset-password/:uidb64/:token" element={<ResetPasswordForm />} />

        {/* Private Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/ehs" element={<BlogListEHS />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/order_manager" element={<OrderManager />} />
          <Route path="/webapps" element={<WebApps />} />

          {/* Kevin's Corner */}
          <Route path="/kevins-corner" element={<BlogListKevin />} />
          <Route path="/kevins-corner/:id" element={<BlogDetailKevin />} />

          {/* News */}
          <Route path="/news" element={<BlogListNews />} />
          <Route path="/news/:id" element={<BlogDetailNews />} />

          {/* Uploads & Marketing */}
          <Route path="/post_upload" element={<PostUploadPage />} />
          <Route path="/marketing-materials" element={<MarketingMaterials />} />

          {/* Dashboard */}
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;