import React, { useState } from 'react';
import { 
  Navbar,
  NavbarBrand, 
  Nav, 
  NavItem, 
  NavLink as BootstrapNavLink,
  NavbarToggler, 
  Collapse, 
  Button 
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faHome, 
  faShieldAlt, 
  faPalette,
  faLaptopCode,
  faChartLine,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../context/AuthContext';
import { useNavigate, NavLink as RouterNavLink } from 'react-router-dom';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { authData, logout, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return <p>Loading...</p>;
  }

  const isLoggedIn = !!authData;

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Navbar
      color="light"
      light
      expand="md"
      className="fixed-top shadow"
      style={{
        padding: "20px 0",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <NavbarBrand href="/" className="d-flex align-items-center">
        <img
          src="/axillion-aerospace-logo.svg"
          width="150"
          alt="Axillion Aerospace Logo"
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} className="border-0" />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto d-flex align-items-center" navbar>
          {isLoggedIn && (
            <>
              <NavItem>
                <BootstrapNavLink
                  tag={RouterNavLink}
                  to="/"
                  end
                  className={({ isActive }) => 
                    `d-flex align-items-center px-4 py-2 ${isActive ? 'active' : ''}`
                  }
                >
                  <FontAwesomeIcon icon={faHome} style={{ marginRight: "8px" }} />
                  Home
                </BootstrapNavLink>
              </NavItem>

              <NavItem>
                <BootstrapNavLink
                  tag={RouterNavLink}
                  to="/ehs"
                  className={({ isActive }) =>
                    `d-flex align-items-center px-4 py-2 ${isActive ? 'active' : ''}`
                  }
                >
                  <FontAwesomeIcon icon={faShieldAlt} style={{ marginRight: "8px" }} />
                  EHS
                </BootstrapNavLink>
              </NavItem>

              <NavItem>
                <BootstrapNavLink
                  tag={RouterNavLink}
                  to="/marketing-materials"
                  className={({ isActive }) =>
                    `d-flex align-items-center px-4 py-2 ${isActive ? 'active' : ''}`
                  }
                >
                  <FontAwesomeIcon icon={faPalette} style={{ marginRight: "8px" }} />
                  Marketing
                </BootstrapNavLink>
              </NavItem>

              <NavItem>
                <BootstrapNavLink
                  tag={RouterNavLink}
                  to="/webapps"
                  className={({ isActive }) =>
                    `d-flex align-items-center px-4 py-2 ${isActive ? 'active' : ''}`
                  }
                >
                  <FontAwesomeIcon icon={faLaptopCode} style={{ marginRight: "8px" }} />
                  Web Apps
                </BootstrapNavLink>
              </NavItem>

              <NavItem>
                <BootstrapNavLink
                  tag={RouterNavLink}
                  to="/dashboard"
                  className={({ isActive }) =>
                    `d-flex align-items-center px-4 py-2 ${isActive ? 'active' : ''}`
                  }
                >
                  <FontAwesomeIcon icon={faChartLine} style={{ marginRight: "8px" }} />
                  Business Intelligence
                </BootstrapNavLink>
              </NavItem>

              <NavItem>
                <Button
                  color="secondary"
                  className="d-flex align-items-center px-4 py-2"
                  style={{
                    backgroundColor: "#d3d3d3",
                    color: "#333",
                    fontWeight: "500",
                    borderRadius: "8px",
                    transition: "background-color 0.3s ease",
                    marginLeft: "10px",
                  }}
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: "8px" }} />
                  Logout
                </Button>
              </NavItem>
            </>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;