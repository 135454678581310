import React, { createContext, useState, useEffect, useContext } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);
  const [loading, setLoading] = useState(true);

  // ✅ Check authentication status on mount
  useEffect(() => {
    checkAuthStatus();
    startTokenRefreshInterval(); // ✅ Automatically refresh tokens
  }, []);

  const refreshAuthStatus = async () => {
    setLoading(true);
    await checkAuthStatus();
    setLoading(false);
  };

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/auth-status/`,
        {
          method: 'GET',
          credentials: 'include', // ✅ Ensures cookies are sent
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.isAuthenticated) {
          setAuthData(data.user);
        } else {
          setAuthData(null);
        }
      } else {
        setAuthData(null);
      }
    } catch (error) {
      console.error("Error checking authentication:", error);
      setAuthData(null);
    } finally {
      setLoading(false);
    }
  };

  // ✅ Define login function (Fixes the ESLint error)
  const login = async ({ email, password }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/login/`,
        {
          method: 'POST',
          credentials: 'include', // ✅ Ensures cookies are sent
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        }
      );

      if (response.ok) {
        await refreshAuthStatus(); // ✅ Refresh authentication state after login
        return { success: true };
      } else {
        const errorData = await response.json();
        return { success: false, error: errorData.detail || 'Invalid email or password' };
      }
    } catch (err) {
      return { success: false, error: 'An unexpected error occurred. Please try again.' };
    }
  };

  // ✅ Token Refresh Function
  const refreshToken = async () => {
    try {
      const response = await fetch(
        'https://api.axillonaerospace.info/api/users/token/refresh/',
        {
          method: 'POST',
          credentials: 'include', // ✅ Ensures refresh token cookie is sent
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log('Token refreshed:');

        // ✅ Refresh auth state
        await refreshAuthStatus();
        return data.access;
      } else {
        console.error('Token refresh failed');
        setAuthData(null); // ✅ Log user out on failure
      }
    } catch (err) {
      console.error('Error refreshing token:', err);
      setAuthData(null);
    }
  };

  // ✅ Automatically refresh token every 14 minutes
  const startTokenRefreshInterval = () => {
    const interval = setInterval(() => {
      refreshToken();
    }, 14 * 60 * 1000); // Refresh 1 minute before the 15-minute expiration
    return () => clearInterval(interval);
  };

  // ✅ Logout function
  const logout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/logout/`,
        {
          method: 'POST',
          credentials: 'include', // ✅ Ensures cookies are included
        }
      );

      if (response.ok) {
        setAuthData(null);
        await refreshAuthStatus();
        console.error('Logout ok:');
      } else {
        console.error('Logout failed:', await response.json());
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook for easy consumption
export const useAuth = () => useContext(AuthContext);