import React from 'react';
import { Box, Card, CardContent, Typography, Link } from '@mui/material';
import CITicketsPage from './charts/CI/CITicketsPage';

const ITDashboard = () => {
    return (
      <Box sx={{ padding: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center' }}>
          CI Dashboard
        </Typography>
  
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 3,
          }}
        >
          {/* 📌 CI Tickets Chart */}
          <Card
            sx={{
              flex: {
                xs: '1 1 100%',  // full width on mobile
                sm: '1 1 100%',  // still full width on small tablets
                md: '1 1 600px'  // 600px wide on medium+ screens
              },
              minHeight: 500
            }}
          >
            <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                  <Link
                  href="https://axillon.atlassian.net/jira/core/projects/CI2/board"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="hover"
                  sx={{ fontWeight: 'bold', color: 'inherit' }}
                  >
                CI Projects by week
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <CITicketsPage />
            </Box>
          </CardContent>
          </Card>
  
  
        </Box>
      </Box>
    );
  };
  
  export default ITDashboard;