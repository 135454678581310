import React from 'react';
import { CardActionArea, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const NewsCard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardActionArea component={Link} to="/news" sx={{ height: '100%' }}>
      <Box
        sx={{
          backgroundColor: 'background.cool_light',
          display: 'flex',
          flexDirection: 'column',
          height: '90%',   
          padding: 2,
          borderRadius: 2,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          margin: '0.5em 0',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: isSmallScreen ? 'center' : 'flex-start',
            gap: 1,
          }}
        >
          <Box
            component="img"
            src="/logo512.png"
            alt="Axillon Logo"
            sx={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          />
          <Box sx={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
            <Typography variant="h5" sx={{ color: 'primary.main' }}>
              Latest News
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
            Stay updated with the latest news, important announcements, and valuable insights from Axillon Aerospace!
            </Typography>
          </Box>
        </Box>
    </Box>
    </CardActionArea>
  );
};

export default NewsCard;