// src/components/sectionsData.js

import {
    faNetworkWired,
    faChartBar,
    faChartLine,
    faHeart,
    faCoins,
    faClock,
    faChalkboardTeacher,
    faServer,
    faShieldAlt,
    faKey,
    faUsers,
    faLightbulb,
    faBriefcase,
    faPlane,
    faDesktop,
    faBuilding,
    faMoneyBillTransfer,
    faWifi
    } from '@fortawesome/free-solid-svg-icons';
  
  // Export an array of section objects
  export const sectionsData = [
    {
      title: 'Axillon',
      color: 'primary',
      links: [
        {
          label: 'IT Support',
          icon: faDesktop,
          url: 'https://axillon.atlassian.net/servicedesk/customer/portal/1',
          requiresVpn: false,
          description: 'IT support ticketing system.',
        },
        {
          label: 'Tableau (Analytics)',
          icon: faChartBar,
          url: 'http://usbipvtableau/#/home',
          requiresVpn: true,
          description: 'Analytics dashboards (VPN required).',
        },
        {
          label: 'Benefits Portal',
          icon: faHeart,
          url: 'https://carbontopco.bswift.com/',
          requiresVpn: false,
          description: 'View and manage your employee benefits here.',
        },
        {
          label: 'UKG Pro',
          icon: faUsers,
          url: 'https://skcappartners.ukg.net',
          requiresVpn: false,
          description: 'Access HR information, payroll, and time off requests.',
        },
        {
          label: 'Recruiting',
          icon: faBriefcase,
          url: 'https://axillonaerospace-openhire.silkroad.com/',
          requiresVpn: false,
          description: 'Our applicant tracking system for job postings and hires.',
        },
        {
          label: 'Travel Bank',
          icon: faPlane,
          url: 'https://app.travelbank.com/user/login?active=email',
          requiresVpn: false,
          description: 'Book and track business travel expenses.',
        },
        {
          label: 'Envoy',
          icon: faBuilding, 
          url: 'https://dashboard.envoy.com/login',
          requiresVpn: false,
          description: 'Manage workplace visitor access and office reservations.',
        },
        {
          label: 'JP Morgan File Transfer',
          icon: faMoneyBillTransfer, 
          url: 'https://ftscata.mfts.jpmchase.com',
          requiresVpn: false,
          description: 'JP Morgan Chase - SFTP File Transfer',
        },
      ],
    },
    {
      title: 'Baltimore',
      color: 'primary',
      links: [
        {
        label: 'Baltimore Intranet',
        icon: faChartBar,
        url: 'http://it-manager/intranet/Site/view.cfm?siteID=1',
        requiresVpn: true,
        description: 'Baltimore Intranet requires access to Baltimore network',
      }
      ] 
    },
    {
      title: 'Rockmart',
      color: 'primary',
      links: [
        {
          label: 'Rockmart Power BI',
          icon: faChartBar,
          url: 'https://app.powerbi.com/home',
          requiresVpn: false,
          description: 'View Rockmart site dashboards and data reports.',
        },
        {
          label: 'Data Analytics',
          icon: faChartBar,
          url: 'https://parkercorp.sharepoint.com/sites/Meggitt_RockmartDataAnalytics',
          requiresVpn: false,
          description: 'SharePoint analytics.',
        },
        {
          label: 'LOA/FMLA link',
          icon: faCoins,
          url: 'https://www.lincolnfinancial.com/',
          requiresVpn: false,
          description: 'Lincoln Financial portal for LOA / FMLA details.',
        },
        {
          label: 'Timekeeping',
          icon: faClock,
          url: 'https://meggittusa.prd.mykronos.com',
          requiresVpn: false,
          description: 'Punch in/out and manage work hours.',
        },
        {
          label: 'Training Portal',
          icon: faChalkboardTeacher,
          url: 'https://meggitt-8585.reach360.com/learn/',
          requiresVpn: false,
          description: 'Online training courses.',
        },
      ],
    },
    {
      title: 'San Diego',
      color: 'primary',
      links: [
        {
          label: 'SCADA System',
          icon: faNetworkWired,
          url: 'http://usbipvscada01:8090/',
          requiresVpn: true,
          description: 'System Control and Data Acquisition (VPN required).',
        },
        {
          label: 'Continuous Improvement',
          icon: faChartLine,
          url: 'https://axillon.atlassian.net/jira/core/projects/CI2/board',
          requiresVpn: false,
          description: 'Submit or view ongoing improvement initiatives.',
        },
        {
          label: 'Submit a bright idea',
          icon: faLightbulb,
          url: 'http://usbipvpython:2005/',
          requiresVpn: true,
          description: 'Innovation portal to share new ideas (VPN required).',
        },
        {
          label: 'Timekeeping',
          icon: faClock,
          url: 'http://usbipvm2msfdc:8080/unanet/action/home',
          requiresVpn: true,
          description: 'Unanet system for managing project hours (VPN required).',
        },
        {
          label: 'Doc Control & Training',
          icon: faChalkboardTeacher,
          url: 'http://usbipvmcapp01.int1.meggittplc.com/mc/login/index.cfm?action=displayLoginPage...',
          requiresVpn: true,
          description: 'Document control and employee training system (VPN required).',
        },
      ],
    },
    {
      title: 'IT',
      color: 'primary',
      links: [
        {
          label: 'San Diego Vsphere',
          icon: faServer,
          url: 'https://usbipvcsa70.int1.meggittplc.com/',
          requiresVpn: true,
          description: 'Manage the San Diego virtual environment (VPN required).',
        },
        {
          label: 'Saltillo Vsphere',
          icon: faServer,
          url: 'https://usbipvcsa70.int1.meggittplc.com/',
          requiresVpn: true,
          description: 'Manage the Saltillo virtual environment (VPN required).',
        },
        {
          label: 'FortiClient',
          icon: faShieldAlt,
          url: 'https://10.212.9.108/login',
          requiresVpn: true,
          description: 'VPN client and security gateway login (VPN required).',
        },
        {
          label: 'Bit Locker Recovery',
          icon: faKey,
          url: 'https://gb1ipvsccm02.meggitt.net/helpdesk/Default.aspx',
          requiresVpn: true,
          description: 'Retrieve recovery keys for BitLocker-encrypted drives (VPN required).',
        },
        {
          label: 'Lumen',
          icon: faWifi,
          url: 'https://controlcenter.lumen.com/enterprise/dashboard',
          requiresVpn: false,
          description: 'Lumen control center for network management',
        },
      ],
    },
  ];