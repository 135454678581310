import React from 'react';
import { Container, Box } from '@mui/material';
import Header from './intranet/Header';
import Footer from './intranet/Footer';

const Layout = ({ children }) => {
  return (
    <Container 
      maxWidth="xl" 
      sx={{
        padding: 4,
        backgroundColor: 'rgba(211, 211, 211, 0.3)', // Light gray transparent background
        minHeight: '100vh'
      }}
    >
      {/* Header: Always visible */}
      <Header />

      {/* Main Content */}
      <Box sx={{ paddingTop: '1rem' }}>
        {children}
      </Box>

      {/* Footer: Always visible */}
      <Footer />
    </Container>
  );
};

export default Layout;