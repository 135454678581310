import { DOMAINS } from '../../../../constants/domains';
import { API_ENDPOINTS } from '../../../../constants/apiEndpoints';
import axios from 'axios';

const BACKEND_AX_KEY = process.env.REACT_APP_BACKEND_AX_KEY;

const apiClient = axios.create({
  baseURL: DOMAINS.jira_data,
  headers: {
    Authorization: `Token ${BACKEND_AX_KEY}`,
  },
});

/**
 * Fetch data from the Jira API.
 * @param {string} endpoint - API endpoint to fetch data from.
 * @param {string} projectKey - Project key for filtering.
 * @returns {Promise<any>} - The API response data.
 */
const fetchTickets = async (endpoint, projectKey) => {
  try {
    const url = `${endpoint}${projectKey}`;
    const { data } = await apiClient.get(url);
    return data;
  } catch (error) {
    console.error(`Error fetching from ${endpoint}:`, error.response?.data || error.message);
    throw error;
  }
};

/** Fetch tickets by project key */
export const fetchTicketsTSA = (projectKey) => fetchTickets(API_ENDPOINTS.TICKETS_TSA, projectKey);

/** Fetch tickets by status */
export const fetchTicketsByStatus = (projectKey) => fetchTickets(API_ENDPOINTS.TICKETS_BY_PROJECT_KEY, projectKey);

export const fetchTicketsNonTSA = (projectKey) => fetchTickets(API_ENDPOINTS.TICKETS_NON_TSA, projectKey);