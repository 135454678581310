// src/components/KevinsCorner/BlogDetail.js

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import blogPostsKevin from './blogPostsKevin';


const BlogDetailKevin = () => {
  const { id } = useParams();
  const post = blogPostsKevin.find((p) => p.id === parseInt(id));

  if (!post) {
    return (
      <Box sx={{ padding: 4 , mt: 4 }}>
        <Typography variant="h4" color="error">
          Post not found.
        </Typography>
        <Button component={Link} to="/kevins-corner" sx={{ mt: 2 }}>
          Back to Kevin's Corner
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 4, mt: 4 }}>
      <Typography variant="h3" sx={{ color: 'primary.main', mb: 2 }}>
        {post.title}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
        {post.date}
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.primary', whiteSpace: 'pre-line' }}>
        {post.content}
      </Typography>

      <Button component={Link} to="/kevins-corner" sx={{ mt: 4, color: 'primary.main' }}>
        ← Back to Kevin's Corner
      </Button>
    </Box>
  );
};

export default BlogDetailKevin;