const blogPostsNews = [
    {
      id: 1,
      title: "Honoring Our Veterans",
      date: "November 11, 2024",
      preview: `Dear Axillon Aerospace Team Members,
  
  As we observe Veterans Day, I would like to take a moment to express our heartfelt gratitude to those of you who have served in the military...`,
      content: `Dear Axillon Aerospace Team Members,
  
  As we observe Veterans Day, I would like to take a moment to express our heartfelt gratitude to those of you who have served in the military. Your dedication, sacrifice, and commitment to protecting our freedoms do not go unnoticed.
  
  We are proud to have you as part of Axillon Aerospace, and we appreciate the unique perspectives and skills you bring from your military experience. Thank you for your bravery, commitment, and the values you uphold.
  
  Today, let us honor all veterans and reflect on the importance of their contributions to our country.`,
    },
    {
      id: 2,
      title: "Celebrating Innovation at Axillon",
      date: "October 15, 2024",
      preview: `Innovation is at the heart of everything we do at Axillon Aerospace. From designing cutting-edge aerospace technology to improving our daily workflows...`,
      content: `Innovation is at the heart of everything we do at Axillon Aerospace. From designing cutting-edge aerospace technology to improving our daily workflows, our commitment to pushing boundaries makes us who we are.
  
  This month, we celebrate the achievements of our engineering team for their groundbreaking work on the Ax-500 prototype. Their creativity and dedication exemplify what it means to be part of this incredible team. Keep pushing the limits and redefining what's possible!`,
    },
    {
      id: 3,
      title: "Employee Spotlight: Jane Doe",
      date: "September 5, 2024",
      preview: `This month, we shine a spotlight on Jane Doe, a senior engineer whose work on propulsion systems has been nothing short of transformative...`,
      content: `This month, we shine a spotlight on Jane Doe, a senior engineer whose work on propulsion systems has been nothing short of transformative. Jane's innovative solutions have significantly improved efficiency, and her mentorship has been invaluable to our newer team members.
  
  Her dedication to continuous learning and team collaboration makes her an inspiration to us all. Thank you, Jane, for your incredible contributions to Axillon Aerospace!`,
    },
  ];
  
  export default blogPostsNews;