import React from 'react';
import FileUploader from './FileLoader';

const validExcelTypes = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const BoeingOrderUploader = () => {
  return (
    <FileUploader
      title="Upload Boeing Customer Orders"
      uploadUrl="/api/upload_boeing_order/"
      acceptedFileTypes={validExcelTypes}
      acceptAttribute=".xls,.xlsx"
      onSuccess={(data) => {
        console.log("Boeing orders uploaded!", data);
      }}
    />
  );
};

export default BoeingOrderUploader;