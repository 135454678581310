import React from 'react';
import { Box, Typography } from '@mui/material';
import ITDashboard from './ITDashboard';
import CIDasjboard from './CIDashboard';

const Dashboard = () => {
  return (
    <Box sx={{ padding: 3 , mt: 5}}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
        Axillon Dashboards
      </Typography>
      <CIDasjboard />

      {/* IT Section */}
      <ITDashboard />

      {/* Future Sections (HR, Finance, etc.) can go here */}
    </Box>
  );
};

export default Dashboard;