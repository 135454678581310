import React from 'react';
import SafranOrderUploader from './SafranOrderUploader';
import BoeingOrderUploader from './BoeingOrderUploader';
import { Box, Typography } from '@mui/material';

const OrderManager = () => {
  return (
    <Box component="main" sx={{ mt: 4 }}>
        <Box
            sx={{
            backgroundColor: '#f9f9f9',
            padding: 3,
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            marginTop: '50px',
            marginBottom: 4,
            textAlign: 'center',
            transition: 'transform 0.3s ease, boxShadow 0.3s ease',
            '&:hover': {
                transform: 'translateY(-8px)',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            },
            }}
        >
        <Typography variant="h4" sx={{ mb: 2, color: 'primary.main' }}>
        Order Manager
        </Typography>
        <Box
        sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center', 
            flexWrap: 'wrap', 
        }}
        >
            <SafranOrderUploader />
            <BoeingOrderUploader />
        </Box>
      </Box>
    </Box>
  );
};

export default OrderManager;