import React from 'react';
import { Box, Typography, Card, CardContent, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks, faFileUpload } from '@fortawesome/free-solid-svg-icons';

const WebApps = () => {
  const navigate = useNavigate();

  const apps = [
    {
      title: 'Order Manager',
      description: 'Manage and upload customer orders seamlessly.',
      icon: faTasks,
      path: '/order_manager'
    },
    {
      title: 'Create New Post',
      description: 'Submit Kevin’s Corner or News posts.',
      icon: faFileUpload,
      path: '/post_upload',    // This route should link to your PostUploadPage
    },
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h3" sx={{ mt: 4, mb: 4, textAlign: 'center', color: 'primary.main' }}>
        Web Applications
      </Typography>

      <Stack direction="row" spacing={4} justifyContent="center" flexWrap="wrap">
        {apps.map((app) => (
          <Box key={app.title} sx={{ width: { xs: '100%', sm: '45%', md: '30%' } }}>
            <Card
              sx={{
                padding: 2,
                backgroundColor: '#f9f9f9',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                <FontAwesomeIcon icon={app.icon} size="3x" style={{ marginBottom: '16px', color: '#1976d2' }} />
                <Typography variant="h5" sx={{ mb: 1 }}>{app.title}</Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>{app.description}</Typography>
                <Button 
                  variant="contained" 
                  color="primary"
                  onClick={() => navigate(app.path)}
                >
                  Open
                </Button>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default WebApps;