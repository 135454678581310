import React from 'react';
import { CardActionArea, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

const KevinsCard = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <CardActionArea component={Link} to="/kevins-corner" sx={{ height: '100%' }}>
      <Box
        sx={{
          //backgroundColor: '#2c5aa0',
          backgroundColor: 'primary.main',
          display: 'flex',
          flexDirection: 'column',
          height: '90%',                 // Ensures the box fills the parent's height
          padding: 2,
          borderRadius: 2,
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          margin: '0.5em 0',                
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isSmallScreen ? 'column' : 'row',
            alignItems: isSmallScreen ? 'center' : 'flex-start',
            gap: 1,
          }}
        >
          <Box
            component="img"
            src="/Kevin.png"
            alt="Kevin smiling"
            sx={{
              width: 80,
              height: 80,
              borderRadius: '50%',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          />
          <Box sx={{ textAlign: isSmallScreen ? 'center' : 'left' }}>
            <Typography variant="h5" sx={{ color: '#ffffff' }}>
              Kevin's Corner
            </Typography>
            <Typography variant="body2" sx={{ mt: 1,color: '#ffffff'  }}>
              Explore Kevin's insights, strategic advice, and solutions designed to drive excellence and growth.
            </Typography>
          </Box>
        </Box>
      </Box>
    </CardActionArea>
  );
};

export default KevinsCard;