import React, { useState, useEffect } from 'react';
import { fetchTicketsByStatus } from '../chartsAPI/jiraApi';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ITTicketsChart = () => {
  const [projectKey, setProjectKey] = useState('SUP'); // Replace with a valid project key
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTicketsByStatus(projectKey);
        //console.log("Fetched IT Tickets Data:", data); // Debugging log
        setTickets(data);
      } catch (error) {
        console.error("Error fetching IT Tickets:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectKey]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Group tickets by site
  const ticketsBySite = tickets.reduce((acc, ticket) => {
    const site = ticket.site || 'Unknown'; // Handle null sites
    if (!acc[site]) {
      acc[site] = [];
    }
    acc[site].push(ticket);
    return acc;
  }, {});

  // Fixed color mapping for each site (Added Baltimore & Erlanger)
  const siteColors = {
    "San Diego": "#3498db",  // Blue
    "Rockmart": "#f39c12",   // Orange
    "Saltillo": "#e74c3c",   // Red
    "Baltimore": "#9b59b6",  // Purple
    "Erlanger": "#2ecc71",   // Green
    "Company Wide": "#34495e", // Dark Grey
    "Unknown": "#7f8c8d",    // Grey
  };

    // Ensure date conversion is correct
  const formatDate = (dateString) => {
    if (!dateString) return 'Unknown';
    const date = new Date(dateString);
    return isNaN(date) ? 'Invalid Date' : date.toLocaleDateString();
  };

// Get today's date (without time)
const today = new Date();

// Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const dayOfWeek = today.getDay();

// Calculate the date for the most recent Sunday
const beginningOfWeek = new Date(today);

// If today is not Sunday, subtract the number of days since the last Sunday
if (dayOfWeek !== 0) {
    beginningOfWeek.setDate(today.getDate() - dayOfWeek);
}

// Format beginningOfWeek to "MM/DD/YYYY" to match the format of your data
const formattedBeginningOfWeek = `${beginningOfWeek.getMonth() + 1}/${beginningOfWeek.getDate()}/${beginningOfWeek.getFullYear()}`;

//console.log('Beginning of the week (Sunday):', formattedBeginningOfWeek);

// Extract unique dates for X-axis labels
let uniqueDates = [...new Set(tickets.map(ticket => formatDate(ticket.week)))];

// Filter out all dates within the current week
uniqueDates = uniqueDates.filter(date => {
  const currentDate = new Date(date);
  return currentDate < beginningOfWeek 
});


// Sort dates in ascending order
uniqueDates.sort((a, b) => new Date(a) - new Date(b));
//console.log('uniqueDates',uniqueDates)

// ✅ Remove the current week from the dataset
//uniqueDates = uniqueDates.filter(date => date !== formattedBeginningOfWeek);

// ✅ Get only the last 8 weeks excluding the current week
const last8Weeks = uniqueDates.slice(-8);

  // Prepare data for the chart
  const chartData = {
    labels: last8Weeks,  // X-axis labels (last 8 weeks)
    datasets: Object.keys(ticketsBySite).map(site => ({
      label: site,
      data: last8Weeks.map(label => {
        const ticket = ticketsBySite[site].find(t => formatDate(t.week) === label);
        return ticket ? ticket.count : null;  // Ensure alignment with labels
      }),
      fill: false,
      borderColor: siteColors[site] || "#bdc3c7", // Line color
      backgroundColor: siteColors[site] || "#bdc3c7", // ✅ Legend will now be a solid color
      tension: 0.1,
    })),
  };

  // ✅ Chart options (Controls tooltips)
  const options = {
    responsive: true,          // Allows the chart to re-size to the container
    maintainAspectRatio: false, // Allows the chart to expand vertically
    plugins: {
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      },
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Weeks',
        },
        grid: {
          display: false, // ✅ Removes grid lines from X-axis
        },
      },
      y: {
        title: {
          display: false,
          text: 'Ticket Count',
        },
        beginAtZero: true,
        grid: {
          display: false, // ✅ Removes grid lines from Y-axis
        },
      },
    },
  };

  return (
    <div style={{ height: '400px' }}>
      <Line data={chartData} options={options} />
    </div>
  );
};

export default ITTicketsChart;