// src/components/CustomizeLinksModal.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Button,
  Box
} from '@mui/material';

/**
 * sectionNames: array of strings like ["Corporate", "Rockmart", "San Diego", "IT"]
 * defaultSelections: object like { Corporate: true, Rockmart: false, ... }
 * onSave: callback(newSelections)
 * onClose: close the modal
 * open: boolean
 */
function CustomizeLinksModal({ open, onClose, onSave, sectionNames, defaultSelections = {} }) {
  const [selections, setSelections] = useState({});

  useEffect(() => {
    if (open) {
      setSelections(defaultSelections);
    }
  }, [open, defaultSelections]);

  const handleToggle = (section) => {
    setSelections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleSave = () => {
    onSave(selections);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Choose Your Sections</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {sectionNames.map((name) => (
            <FormControlLabel
              key={name}
              control={
                <Switch
                  checked={Boolean(selections[name])}
                  onChange={() => handleToggle(name)}
                  color="primary"
                />
              }
              label={name}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CustomizeLinksModal;