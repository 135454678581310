import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const PrivateRoute = () => {
  const { authData, loading } = useAuth();

  if (loading) {
    return (
      <div style={{ marginTop: '30px', textAlign: 'center' }}> 
        <p>Loading...</p> 
      </div>
    ); // ✅ Added margin-top and centered text
  }

  return authData ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;