import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';

/**
 * Generic file uploader that can be reused for different endpoints & file types.
 *
 * @param {Object} props
 * @param {string} props.title - Title/heading displayed above the file upload UI.
 * @param {string} props.uploadUrl - The API endpoint to which the file is posted.
 * @param {string[]} props.acceptedFileTypes - Array of MIME types (e.g., ['application/vnd.ms-excel']).
 * @param {string} [props.acceptAttribute] - Optional string for the `accept` attribute (e.g. ".xls,.xlsx").
 * @param {function} [props.onSuccess] - Callback fired if the upload is successful.
 * @param {function} [props.onError] - Callback fired if the upload fails.
 * @param {Object} [props.style] - Custom SX or style object for the container.
 */
const FileUploader = ({
  title,
  uploadUrl,
  acceptedFileTypes,
  acceptAttribute,
  onSuccess,
  onError,
  style,
}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar(); // Initialize Notistack's enqueueSnackbar

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // Validate file type if needed
    if (
      selectedFile &&
      acceptedFileTypes.length > 0 &&
      !acceptedFileTypes.includes(selectedFile.type)
    ) {
      enqueueSnackbar('Invalid file type.', { variant: 'error' });
      setFile(null);
      return;
    }
    setFile(selectedFile);
  };

  const handleFileUpload = async () => {
    if (!file) {
      enqueueSnackbar('Please select a file first!', { variant: 'warning' });
      return;
    }

    setLoading(true);
    const data = new FormData();
    data.append('file', file);

    // Retrieve auth data (if available) from localStorage
    const authDataString = localStorage.getItem('authData');
    let headers = { 'Content-Type': 'multipart/form-data' };

    if (authDataString) {
      const authData = JSON.parse(authDataString);
      if (authData?.accessToken) {
        headers.Authorization = `Bearer ${authData.accessToken}`;
      }
    }

    try {
      const response = await axios.post(uploadUrl, data, { headers });
      enqueueSnackbar('File uploaded successfully!', { variant: 'success' });

      if (onSuccess) onSuccess(response.data);
    } catch (error) {
      console.error('Error uploading the file:', error);
      const errorMessage =
        error.response?.data?.error ||
        'There was a problem uploading the file. Please try again.';
      enqueueSnackbar(`Error: ${errorMessage}`, { variant: 'error' });

      if (onError) onError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        maxWidth: 480,
        mx: 'auto',
        mt: 4,
        border: '1px solid #ccc',
        borderRadius: 2,
        ...style, // Merge any custom styling
      }}
    >
      {title && (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      )}

      {/* Button for selecting file */}
      <Button
        variant="contained"
        component="label"
        disabled={loading}
        sx={{ mt: 2 }}
      >
        Select File
        <input
          type="file"
          hidden
          onChange={handleFileChange}
          accept={acceptAttribute}
        />
      </Button>

      {/* Display the chosen file name if present */}
      {file && (
        <Typography variant="body1" sx={{ mt: 1 }}>
          Selected File: {file.name}
        </Typography>
      )}

      {/* Upload button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleFileUpload}
        disabled={loading || !file}
        sx={{ mt: 2 }}
      >
        {loading ? 'Uploading...' : 'Upload'}
      </Button>

      {/* Show a linear progress bar while uploading */}
      {loading && (
        <Box sx={{ mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

// Wrap FileUploader in the SnackbarProvider
const FileUploaderWithProvider = (props) => (
  <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
    <FileUploader {...props} />
  </SnackbarProvider>
);

export default FileUploaderWithProvider;