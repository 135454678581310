import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import datalabels plugin
import 'chart.js/auto';
import { fetchTicketsTSA } from '../chartsAPI/jiraApi'; 

const TSATickets = () => {
  const [projectKey, setProjectKey] = useState('ITP'); // Replace with a valid project key
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTicketsTSA(projectKey);
        setTickets(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectKey]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  // Define the preferred order of statuses
  const statusOrder = [
    "To Do", "In Progress", "Contacting Vendor", "Negotiating",
    "Deploying", "Data Extract Required", "Done", "Retire"
  ];

  // Fixed colors for each status (index-based)
  const statusColors = {
    "To Do": "#e74c3c", // Red
    "In Progress": "#85C1E9", // Light Blue
    "Contacting Vendor": "#5DADE2", // Medium Light Blue
    "Negotiating": "#3498DB", // Medium Blue
    "Deploying": "#2E86C1", // Dark Blue
    "Data Extract Required": "#1B4F72", // Deep Dark Blue
    "Done": "#2ecc71", //  Green
    "Retire": "#2ecc71", // Green
  };

  // Sort tickets based on predefined order, with unknown statuses appearing last
  const sortedTickets = tickets.sort((a, b) => {
    const indexA = statusOrder.indexOf(a.status);
    const indexB = statusOrder.indexOf(b.status);
    if (indexA === -1 && indexB === -1) return 0; // Both unknown, keep order
    if (indexA === -1) return 1; // Move unknown status to the end
    if (indexB === -1) return -1; // Move known status ahead
    return indexA - indexB; // Sort by predefined order
  });

  // Extract sorted labels (statuses) and values (counts)
  const labels = sortedTickets.map(ticket => ticket.status);
  const dataValues = sortedTickets.map(ticket => ticket.count);

  // Prepare data for the bar chart
  const chartData = {
    labels,
    datasets: [
      {
        label: 'Tickets by Status',
        data: dataValues,
        backgroundColor: labels.map(status => statusColors[status] || "#bdc3c7"), // Default to grey for unknown statuses
        borderColor: "#000",
        borderWidth: 1,
      },
    ],
  };

  // Chart options (ROTATED BAR CHART with TOTALS)
  const chartOptions = {
    responsive: true,          // Allows the chart to re-size to the container
    maintainAspectRatio: false, // Allows the chart to expand vertically
    indexAxis: 'y', // Rotate the chart to be horizontal
    plugins: {
      legend: { display: false }, // Hide legend
      datalabels: {
        anchor: 'end', // Position labels at the end of bars
        align: 'right',
        formatter: (value) => value, // Display actual count
        font: {
          weight: 'bold',
          size: 14,
        },
        color: '#000',
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: false,
          text: 'Count',
        },
        grid: {
          display: false, // ✅ Removes grid lines from X-axis
        },
      },
      y: {
        title: {
          display: false,
          text: 'Status',
        },
        grid: {
          display: false, // ✅ Removes grid lines from Y-axis
        },
      },
    },
  };

  return (
    <div style={{ height: '400px' }}>
      <Bar data={chartData} options={chartOptions} plugins={[ChartDataLabels]} />
    </div>
  );
};

export default TSATickets;