import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Typography, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomizeLinksModal from './CustomizeLinksModal';
import { sectionsData } from './sectionsData';

const PREFERENCES_URL = 'https://api.axillonaerospace.info/api/user-preferences/';

// Create a reusable LinkButton component
const LinkButton = ({ color, icon, label, url, requiresVpn, description }) => {
  return (
    <Tooltip title={description || ''} arrow placement="top">
      <Button
        variant="contained"
        color={requiresVpn ? 'secondary' : color}
        startIcon={<FontAwesomeIcon icon={icon}/>}
        aria-label={label}
        onClick={() => window.open(url, '_blank')}
        sx={{ padding: 2, minWidth: 200 }}
      >
        {label}
      </Button>
    </Tooltip>
  );
};

const Links = () => {
  const [userSelections, setUserSelections] = useState({});
  const [showModal, setShowModal] = useState(false);

  // ✅ Fetch user preferences from the backend using HttpOnly cookie authentication
  useEffect(() => {
    axios
      .get(PREFERENCES_URL, { withCredentials: true }) // ✅ No Authorization header needed
      .then((response) => {
        setUserSelections(response.data.sections || {});
      })
      .catch((error) => {
        console.error('Error fetching user preferences:', error);
      });
  }, []);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // ✅ Save toggles to Django using cookies for authentication
  const handleSave = (newSelections) => {
    axios
      .put(PREFERENCES_URL, { sections: newSelections }, { withCredentials: true }) // ✅ No Authorization header needed
      .then((res) => {
        console.log('Updated preferences:', res.data);
        setUserSelections(res.data.sections || {});
      })
      .catch((err) => {
        console.error('Error updating preferences:', err);
      });
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url(/white.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundColor: 'rgba(255, 255, 255, 0.91)',
        backgroundBlendMode: 'overlay',
        padding: 2,
        borderRadius: 2,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'translateY(-8px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      {/* Manage Sections Button */}
      <Box sx={{ textAlign: 'right' }}>
        <Button onClick={handleOpenModal} sx={{ fontSize: '1.15rem' }}>
          Manage Links
        </Button>
      </Box>

      {/* Render sections based on user preferences */}
      {sectionsData.map((section) => {
        if (userSelections[section.title] === false) return null;
        return (
          <Box key={section.title} sx={{ mt: 1.5 }}>
            <Typography variant="h4" sx={{ mb: 1, color: 'primary.main' }}>
              {section.title}
            </Typography>

            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
              {section.links.map((link) => (
                <LinkButton
                  key={link.label}
                  color={section.color}
                  icon={link.icon}
                  label={link.label}
                  url={link.url}
                  requiresVpn={link.requiresVpn}
                  description={link.description}
                />
              ))}
            </Box>
          </Box>
        );
      })}

      {/* Legend/Key */}
      <Box sx={{ marginTop: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 20, height: 20, backgroundColor: 'secondary.main' }} />
          <Typography variant="body2">Requires VPN</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ width: 20, height: 20, backgroundColor: 'primary.main' }} />
          <Typography variant="body2">No VPN required</Typography>
        </Box>
      </Box>

      {/* Modal for toggles */}
      <CustomizeLinksModal
        open={showModal}
        onClose={handleCloseModal}
        onSave={handleSave}
        sectionNames={sectionsData.map((s) => s.title)}
        defaultSelections={userSelections}
      />
    </Box>
  );
};

export default Links;