import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  LinearProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { useSnackbar } from 'notistack';

const PostUploadForm = () => {
  const { enqueueSnackbar } = useSnackbar();

  // Form fields
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [postDate, setPostDate] = useState('');
  const [postType, setPostType] = useState('Kevin');
  const [file, setFile] = useState(null);

  // Toggle state for notify_users
  const [notifyUsers, setNotifyUsers] = useState(false);

  // UI state
  const [loading, setLoading] = useState(false);

  // Allowed file types
  const validFileTypes = [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/pdf',
    'image/jpeg',
    'image/png',
  ];

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && validFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);
    } else {
      enqueueSnackbar('Invalid file type. Please select a PPT, PDF, or image file.', { variant: 'error' });
      setFile(null);
    }
  };

  // Handle upload
  const handleUpload = async () => {
    if (!title || !description || !postDate || !postType) {
      enqueueSnackbar('Please fill all required fields: Title, Description, Date, and Post Type.', { variant: 'warning' });
      return;
    }
    if (!file) {
      enqueueSnackbar('Please select a file.', { variant: 'warning' });
      return;
    }

    setLoading(true);

    // Prepare form data
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('date', postDate);
    formData.append('post_type', postType);
    formData.append('file', file);

    // Convert boolean notifyUsers to a string for FormData
    formData.append('notify_users', notifyUsers.toString());

    console.log('Form Data Being Sent:', {
      title,
      description,
      date: postDate,
      postType,
      fileName: file?.name,
      notify_users: notifyUsers,
    });

    try {
      const response = await axios.post(
        'https://api.axillonaerospace.info/api/upload_post/',
        formData,
        {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );

      enqueueSnackbar('Post uploaded successfully!', { variant: 'success' });
      console.log('Server Response:', response.data);

      // Reset form
      setTitle('');
      setDescription('');
      setPostDate('');
      setPostType('Kevin');
      setFile(null);
      setNotifyUsers(false);
    } catch (error) {
      console.error('Error uploading the post:', error);
      const errorMessage = error.response?.data?.error || 'There was a problem uploading the post. Please try again.';
      enqueueSnackbar(`Error: ${errorMessage}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card sx={{ maxWidth: 600, mx: 'auto', mt: 7 }}>
      <CardContent>
        <Typography variant="h5" sx={{ mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
          Create a New Post
        </Typography>

        {/* Title */}
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Description */}
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Date */}
        <TextField
          label="Date for the Post"
          type="date"
          variant="outlined"
          fullWidth
          required
          value={postDate}
          onChange={(e) => setPostDate(e.target.value)}
          sx={{ mb: 2 }}
          InputLabelProps={{ shrink: true }}
        />

        {/* Post Type */}
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="post-type-label">Post Type</InputLabel>
          <Select
            labelId="post-type-label"
            id="post-type-select"
            label="Post Type"
            value={postType}
            onChange={(e) => setPostType(e.target.value)}
          >
            <MenuItem value="Kevin">Kevin's Corner</MenuItem>
            <MenuItem value="News">News Post</MenuItem>
            <MenuItem value="EHS">Environmental Health and Safety</MenuItem>
          </Select>
        </FormControl>

        {/* Notify Users Toggle (Switch) */}
        <FormControlLabel
          control={
            <Switch
              checked={notifyUsers}
              onChange={(e) => setNotifyUsers(e.target.checked)}
              color="primary"
            />
          }
          label="Notify All Users"
          sx={{ mb: 2 }}
        />

        {/* File Upload */}
        <Button variant="contained" component="label" sx={{ mb: 2 }}>
          Select File
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
        {file && (
          <Typography variant="body2" sx={{ mb: 2 }}>
            Selected File: {file.name}
          </Typography>
        )}

        {/* Upload Button */}
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleUpload}
          fullWidth
        >
          {loading ? 'Uploading...' : 'Upload Post'}
        </Button>

        {/* Loading Indicator */}
        {loading && (
          <Box sx={{ mt: 2 }}>
            <LinearProgress />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default PostUploadForm;