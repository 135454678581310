import React from 'react';
import { Box, Container, Typography, Divider } from '@mui/material';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        //backgroundColor: 'secondary.main',
        backgroundImage: 'url(/Carbon.png)',  // Path to the image in the public directory
        backgroundSize: 'cover',              // Ensures the image covers the entire box
        backgroundPosition: 'center',         // Centers the image
        backgroundRepeat: 'no-repeat',        // Prevents the image from repeating
        color: 'white',
        py: 2,
        mt: 2,
      }}
    >
      <Container maxWidth="lg">
        <Divider sx={{ mb: 2, borderColor: 'white' }} />
        <Typography variant="h5" align="center" gutterBottom>
          Axillon Aerospace - Strength Through Innovation
        </Typography>
        <Typography variant="body2" align="center">
          © {currentYear} Axillon Aerospace. All rights reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;